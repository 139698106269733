/*Added for browser compatibility*/
[hidden] {
  display: none;
}

select,
select option {
  color: $c-black;
}

select:invalid,
select option[value=""] {
  color: $c-gray;
}

.form {
  &__label {
    display: grid;
    justify-self: start;
    pointer-events: none;
    transition: all 0.2s;
    margin-bottom: 0.3rem;

    &--1 {
      float: left;
      padding-left: 1rem;
      color: $c-blue;
      margin-bottom: 0.5rem;
    }

    &--error {
      display: grid;
      justify-items: start;
      font-size: $font-14;
      text-transform: lowercase;
      margin-top: 0.5rem;
      margin-left: 1rem;
      color: $c-yellow;
      display: inline-block;

      &:first-letter {
        color: $c-yellow;
        text-transform: uppercase;
      }
    }
    &--warning {
      display: grid;
      justify-items: start;
      justify-self: start;
      font-size: $font-14;
      text-transform: lowercase;
      text-align: left;
      margin-top: 0.5rem;
      margin-left: 1rem;
      color: $c-aaa;
      display: inline-block;

      &:first-letter {
        color: $c-aaa;
        text-transform: uppercase;
      }
    }

    &--success {
      color: $c-green;
      display: grid;
      justify-items: start;
      font-size: $font-14;
      text-transform: lowercase;
      margin-top: 0.2rem;
      display: inline-block;

      &:first-letter {
        color: $c-green;
        text-transform: uppercase;
      }
    }

    &--white {
      color: $c-white;
      display: grid;
      justify-items: start;
      font-size: $font-14;
      text-transform: lowercase;
      margin-top: 0.2rem;
      display: inline-block;
      &:first-letter {
        color: $c-white;
        text-transform: uppercase;
      }
    }

    & :first-letter {
      text-transform: uppercase;
    }
  }

  &__input {
    width: 100%;
    height: 5.4rem;
    padding: 1.2rem 2rem;
    box-sizing: border-box;
    -webkit-appearance: none;
    font-family: inherit;
    font-weight: $weight-400;
    font-size: $font-16;
    //border: none;
    border: 1px solid $c-c4;
    border-radius: $font-2;
    transition: all 0.3s;
    background-color: $c-white;
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    -ms-border-radius: 0.3rem;
    -o-border-radius: 0.3rem;

    &:focus {
      border: 1px solid $c-d-blue;
      outline: none; // it work in also ms-edge
      border-radius: $font-2;
      -webkit-border-radius: $font-2;
      -moz-border-radius: $font-2;
      -ms-border-radius: $font-2;
      -o-border-radius: $font-2;
    }

    &::-webkit-input-placeholder {
      color: $c-gray;
    }

    &:-ms-input-placeholder {
      color: $c-gray;
    }

    &::placeholder {
      color: $c-gray;
    }

    // &[type="submit"] {
    //   background-color: rgba($c-blue, 0.1);
    //   color: $c-blue;
    //   text-transform: uppercase;
    //   font-size: inherit;
    //   border: none;
    //   cursor: pointer;
    //   padding: 1rem 2rem;
    // }

    // &[type="submit"]:hover {
    //   color: $c-white;
    //   background-color: $c-blue;
    // }
  }

  &__search {
    display: grid;
    align-content: center;
    align-self: center;
    width: 100%;
    padding: 1.04rem 2rem;
    box-sizing: border-box;
    -webkit-appearance: none;
    font-family: inherit;
    font-weight: $weight-400;
    font-size: $font-16;
    border: 1px solid $c-c4;
    transition: all 0.3s;
    background-color: $c-white;
    border-radius: 0.3rem;
    border-radius: $font-3;
    -webkit-border-radius: $font-3;
    -moz-border-radius: $font-3;
    -ms-border-radius: $font-3;
    -o-border-radius: $font-3;

    &:focus {
      border: 1px solid $c-d-blue;
      outline: none; // it work in also ms-edge
      border-radius: $font-2;
      -webkit-border-radius: $font-2;
      -moz-border-radius: $font-2;
      -ms-border-radius: $font-2;
      -o-border-radius: $font-2;
    }

    &::-webkit-input-placeholder {
      color: $c-gray;
    }

    &:-ms-input-placeholder {
      color: $c-gray;
    }

    &::placeholder {
      color: $c-gray;
    }
  }

  &__select {
    width: 100%;
    height: 5.4rem;
    padding: 1.7rem 2rem;
    box-sizing: border-box;
    -webkit-appearance: none;
    font-family: inherit;
    font-weight: $weight-400;
    font-size: $font-16;
    border: 1px solid $c-c4;
    border-radius: $font-2;
    transition: all 0.3s;
    background-color: $c-white;
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    -ms-border-radius: 0.3rem;
    -o-border-radius: 0.3rem;

    &:focus {
      border: 1px solid $c-d-blue;
      outline: $c-d-blue; // it work in also ms-edge
    }
  }

  &__select__2 {
    font-size: $font-16;

    &:hover {
      cursor: pointer;
    }

    // &:focus {
    //   // border: none;
    // }
  }
}
