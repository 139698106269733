disabled {
  pointer-events: none;
  opacity: 0.3;
}

.btn {
  &,
  // &:link,
  &:visited {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -webkit-appearance: none;
    outline: none;
    text-decoration: none;
    font-size: inherit;
    padding: 1.2rem 3rem;
    border: 1px solid transparent;
    transition: all 0.2s;
    cursor: pointer;
    border-radius: .3rem;
    -webkit-border-radius: .3rem;
    -moz-border-radius: .3rem;
    -ms-border-radius: .3rem;
    -o-border-radius: .3rem;
  
}
  &__brd {
    display: grid;
    align-content: center;
    align-self: center;
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    -ms-border-radius: 0.3rem;
    -o-border-radius: 0.3rem;
}

  &__blue {
    background-color: $c-btn-blue;
    color: $c-white;
  }
  &__blueSemi {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-gap: 0.8rem;
    align-items: center;
    background-color: $c-yellow-primary;
    color: $c-white;
    padding: 0.8rem 2rem;
    border-radius: 10rem;
    -webkit-border-radius: 10rem;
    -moz-border-radius: 10rem;
    -ms-border-radius: 10rem;
    -o-border-radius: 10rem;
  }
  &__red {
    background-color: $c-red;
    color: $c-white;
  }
}

.btn__border {
  background-color: transparent;
  border: 1px solid $c-btn-blue !important;
  color: $c-btn-blue;
  padding: 1.1rem 4rem;
  display: grid;
  align-content: center;
  align-self: center;
}

.btn-svg {
  display: grid;
  grid-template-columns: max-content min-content;
  grid-column-gap: 0.8rem;
  align-items: center;
  justify-self: start;
}
