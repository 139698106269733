.container {
  &__ul {
    position: relative;
    width: 100%;
    height: 100%;
  }
  &__li {
    display: grid;
    align-items: center;
    align-content: center;
  }
}

.content {
  &__links {
    display: grid;
    grid-template-columns: min-content 1fr;
    align-items: center;
    grid-gap: 1rem;
    padding: 2rem !important;
    border-bottom: 1px solid $c-c9;
    color: $c-black;
    text-decoration: none;

    &--2 {
      grid-template-columns: min-content 1fr 1fr !important;
    }

    &:hover {
      background-color: rgba($c-l-blue, 0.07);
    }
    &__sub {
      grid-template-columns: min-content 1fr min-content;
    }
  }
  &__hovered {
    background-color: rgba($c-l-blue, 0.07);
  }

  &__ul {
    background-color: $c-white;
    width: max-content !important;
    top: 7rem;
    box-shadow: 0rem 0.4rem 2.4rem rgba($c-btn-blue, 0.13);

    &--2 {
      top: 0 !important;
      position: absolute !important;
      right: 23.5rem !important;
      @media only screen and (max-width: $bp-small) {
        right: 4.5rem !important;
      }
    }
    &--acc {
      top: 0 !important;
      position: absolute !important;
      right: 17.5rem !important;
      @media only screen and (max-width: $bp-small) {
        right: 4.5rem !important;
      }
    }
    &--3 {
      top: 0 !important;
      position: absolute !important;
      right: 15.5rem !important;
      @media only screen and (max-width: $bp-small) {
        top: 5rem !important;
        right: 2rem !important;
      }
    }
    &--4 {
      top: 0 !important;
      position: absolute !important;
      right: 21.5rem !important;
      @media only screen and (max-width: $bp-small) {
        top: 5rem !important;
        right: 2rem !important;
      }
    }
    &--5 {
      top: 0 !important;
      position: absolute !important;
      right: 14rem !important;
      @media only screen and (max-width: $bp-small) {
        top: 5rem !important;
        right: 2rem !important;
      }
    }
    &--6 {
      top: 0 !important;
      position: absolute !important;
      right: 29rem !important;
      @media only screen and (max-width: $bp-small) {
        top: 5rem !important;
        right: 2rem !important;
      }
    }
  }

  &__li {
    width: 100%;
    display: block;
    transition-duration: 0.5s;

    &:hover {
      cursor: pointer;
    }
  }

  &__dropdown__icon--right {
    display: grid;
    justify-items: end;
  }
}
.container__ul:hover .container__li .dropdown__icon {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.container__ul .container__li .content__ul {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  transition: all 0.5s ease;
  // margin-top: 0;
  // margin-top: 1rem;
  right: 0; // dropdown showing content flow going right to left if replace right=0 to left=0 then content flow showing left to right
  display: none;
}
.container__ul .container__li:hover > .content__ul,
.container__ul .container__li:focus-within > .content__ul,
.container__ul .container__li .content__ul:hover {
  visibility: visible;
  opacity: 1;
  display: block;
  z-index: 2999;
}
.container__ul .container__li .content__li .content__ul,
.container__ul .container__li .content__li .content__ul {
  clear: both;
  width: 100%;
}
