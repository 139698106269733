@import "reactjs-popup/dist/index.css";
@import "sass/root.scss";

/* Login */
.login {
  &__story {
    height: 100vh;
    grid-column: full-start / col-end 4;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    background-color: $c-white;

    @media only screen and (max-width: $bp-medium) {
      height: 8rem;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      width: 100% !important;
      grid-row: 1/2;
      grid-column: 1/-1;
    }
  }

  &__content {
    height: 100vh;
    overflow-y: scroll;
    grid-column: col-start 5 / full-end;
    background-image: linear-gradient(147.98deg, $c-l-blue 0%, $c-d-blue 100%);
    padding: 2rem 0;
    display: grid;
    align-items: center;
    justify-items: center;
    color: $c-white;

    @media only screen and (max-width: $bp-medium) {
      grid-column: full-start/full-end;
      grid-row: 2/3;
      height: calc(100vh - 8rem);
    }
  }

  &__img {
    width: 100%;
    grid-row: 1/6;
    grid-column: 1/6;
    object-fit: cover;
    object-position: 100%;
    display: grid;
    align-self: center;
    align-content: center;
    z-index: 20;

    @media only screen and (max-width: $bp-medium) {
      display: none;
    }

    &--2 {
      width: 25rem;
      margin-top: 2rem;

      @media only screen and (max-width: $bp-medium) {
        width: 13rem;
        margin-top: 1rem;
      }
    }
  }

  &__text {
    grid-row: 6/7;
    grid-column: 1/7;
    z-index: 100;
    padding: 2rem 6rem;
    display: grid;
    align-items: center;

    @media only screen and (max-width: $bp-medium) {
      padding: 1rem;
    }

    &--size {
      font-weight: 700;
      font-family: $font-display;
      line-height: 1.2;
      font-size: 4rem;

      @media only screen and (max-width: $bp-medium) {
        font-size: $font-12;
      }
    }
  }

  &__box {
    display: grid;
    align-items: center;
    justify-items: start;
    width: 33rem !important;

    @media only screen and (max-width: $bp-small) {
      width: 30rem !important;
    }
  }

  &__w32 {
    width: 32.5rem !important;

    @media only screen and (max-width: $bp-small) {
      width: 30rem !important;
    }
  }

  &__btn {
    width: 32.5rem !important;
    padding: 1.8rem 5rem !important;
    background-color: $c-btn-blue;
    box-shadow: 0px 0px 1px rgba($c-btn-blue, 0.13),
      0px 2px 4px rgba($c-btn-blue, 0.13);
    border-radius: 0.3rem !important;
    -webkit-border-radius: 0.3rem !important;
    -moz-border-radius: 0.3rem !important;
    -ms-border-radius: 0.3rem !important;
    -o-border-radius: 0.3rem !important;
    color: $c-white;
    font-weight: $weight-700;

    @media only screen and (max-width: $bp-small) {
      width: 30rem !important;
    }
  }

  &__link {
    color: $c-white;
  }
}

/* OTP */
.otp {
  &__story {
    height: 100vh;
    grid-column: full-start / col-end 4;

    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    background-color: $c-white;

    @media only screen and (max-width: $bp-medium) {
      height: 8rem;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      width: 100% !important;
      grid-row: 1/2;
      grid-column: 1/-1;
    }
  }

  &__content {
    height: 100vh;
    overflow-y: scroll;
    grid-column: col-start 5 / full-end;
    background-image: linear-gradient(147.98deg, $c-l-blue 0%, $c-d-blue 100%);
    padding: 2rem 0;
    display: grid;
    align-items: center;
    justify-items: center;
    color: $c-white;

    @media only screen and (max-width: $bp-medium) {
      grid-column: full-start/full-end;
      grid-row: 2/3;
      height: calc(100vh - 8rem);
    }
  }

  &__img {
    width: 100%;
    grid-row: 1/6;
    grid-column: 1/6;
    object-fit: cover;
    object-position: 100%;
    display: grid;
    align-self: center;
    align-content: center;
    z-index: 20;

    @media only screen and (max-width: $bp-medium) {
      display: none;
    }

    &--2 {
      width: 25rem;
      margin-top: 2rem;

      @media only screen and (max-width: $bp-medium) {
        width: 13rem;
        margin-top: 1rem;
      }
    }
  }

  &__text {
    grid-row: 6/7;
    grid-column: 1/7;
    z-index: 100;
    padding: 2rem 6rem;
    display: grid;
    align-items: center;

    @media only screen and (max-width: $bp-medium) {
      padding: 1rem;
    }

    &--size {
      font-weight: 700;
      font-family: $font-display;
      line-height: 1.2;
      font-size: 4rem;

      @media only screen and (max-width: $bp-medium) {
        font-size: $font-12;
      }
    }
  }

  &__box {
    display: grid;
    align-items: center;
    justify-items: start;
    width: 33rem !important;

    @media only screen and (max-width: $bp-small) {
      width: 30rem !important;
    }
  }

  &__btn {
    width: 32.5rem !important;
    padding: 1.8rem 5rem !important;
    background-color: $c-btn-blue;
    box-shadow: 0px 0px 1px rgba($c-btn-blue, 0.13),
      0px 2px 4px rgba($c-btn-blue, 0.13);
    border-radius: 0.3rem !important;
    -webkit-border-radius: 0.3rem !important;
    -moz-border-radius: 0.3rem !important;
    -ms-border-radius: 0.3rem !important;
    -o-border-radius: 0.3rem !important;
    color: $c-white;
    font-weight: $weight-700;

    @media only screen and (max-width: $bp-small) {
      width: 30rem !important;
    }
  }

  &__link {
    color: $c-white;
  }

  &__resends {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 32.5rem !important;

    @media only screen and (max-width: $bp-small) {
      width: 30rem !important;
    }
  }

  &__resend {
    display: grid;
    justify-content: end;
    align-items: center;
    font-size: $font-12;

    &:hover {
      cursor: pointer;
    }
  }
}

/* Alert */
.alert__border {
  padding: 0.4rem 1rem;
  border-radius: 0.3rem;
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  -ms-border-radius: 0.3rem;
  -o-border-radius: 0.3rem;
  // box-shadow: 0 0 .1rem rgba($c-btn-blue, 0.13), 0 .2rem .4rem rgba($c-btn-blue, 0.13);
}

/* Register */
.register {
  &__story {
    height: 100vh;
    grid-column: full-start / col-end 4;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    background-color: $c-white;

    @media only screen and (max-width: $bp-medium) {
      height: 8rem;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      width: 100% !important;
      grid-row: 1/2;
      grid-column: 1/-1;
    }
  }

  &__content {
    height: 100vh;
    overflow-y: scroll;
    grid-column: col-start 5 / full-end;
    background-image: linear-gradient(147.98deg, $c-l-blue 0%, $c-d-blue 100%);
    padding: 2rem 0;

    display: grid;
    align-items: center;
    justify-items: center;
    color: $c-white;

    @media only screen and (max-width: $bp-medium) {
      grid-column: full-start/full-end;
      grid-row: 2/3;
      height: calc(100vh - 8rem);
    }
  }

  &__img {
    width: 100%;
    grid-row: 1/6;
    grid-column: 1/6;
    object-fit: cover;
    object-position: 100%;
    display: grid;
    align-self: center;
    align-content: center;
    z-index: 20;

    @media only screen and (max-width: $bp-medium) {
      display: none;
    }

    &--2 {
      width: 25rem;
      margin-top: 2rem;

      @media only screen and (max-width: $bp-medium) {
        width: 13rem;
        margin-top: 1rem;
      }
    }
  }

  &__text {
    // grid-row: 5/6;
    // grid-column: 1/7;
    grid-row: 6/7;
    grid-column: 1/7;
    z-index: 100;
    padding: 2rem 6rem;
    display: grid;
    align-items: center;

    @media only screen and (max-width: $bp-medium) {
      padding: 1rem;
    }

    &--size {
      font-weight: 700;
      font-family: $font-display;
      line-height: 1.2;
      font-size: 4rem;

      @media only screen and (max-width: $bp-medium) {
        font-size: $font-12;
      }
    }

    // }
  }

  &__box {
    display: grid;
    align-items: center;
    justify-items: start;
    width: 33rem !important;

    @media only screen and (max-width: $bp-small) {
      width: 30rem !important;
    }
  }

  &__w32 {
    width: 32.5rem !important;

    @media only screen and (max-width: $bp-small) {
      width: 30rem !important;
    }
  }

  &__btn {
    width: 32.5rem !important;
    padding: 1.8rem 5rem !important;
    background-color: $c-btn-blue;
    box-shadow: 0px 0px 1px rgba($c-btn-blue, 0.13),
      0px 2px 4px rgba($c-btn-blue, 0.13);
    border-radius: 0.3rem !important;
    -webkit-border-radius: 0.3rem !important;
    -moz-border-radius: 0.3rem !important;
    -ms-border-radius: 0.3rem !important;
    -o-border-radius: 0.3rem !important;
    color: $c-white;
    font-weight: $weight-700;

    @media only screen and (max-width: $bp-medium) {
      padding: 1rem 5rem !important;
    }

    @media only screen and (max-width: $bp-small) {
      width: 30rem !important;
    }
  }

  &__link {
    color: $c-white;
  }
}

/* Register Framework */
.financial {
  &__story {
    height: 100vh;
    grid-column: full-start / col-end 4;

    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    background-color: $c-white;

    @media only screen and (max-width: $bp-medium) {
      height: 8rem;
      width: 100% !important;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      grid-row: 1/2;
      grid-column: 1/-1;
    }
  }

  &__content {
    height: 100vh;
    overflow-y: scroll;
    padding: 2rem 0;
    grid-column: col-start 5 / full-end;
    background-image: linear-gradient(147.98deg, $c-l-blue 0%, $c-d-blue 100%);

    display: grid;
    align-items: center;
    justify-items: center;
    color: $c-white;

    @media only screen and (max-width: $bp-medium) {
      grid-column: full-start/full-end;
      grid-row: 2/3;
      height: calc(100vh - 8rem);
    }
  }

  &__img {
    width: 100%;
    grid-row: 1/6;
    grid-column: 1/6;
    object-fit: cover;
    object-position: 100%;
    display: grid;
    align-self: center;
    align-content: center;
    z-index: 20;

    @media only screen and (max-width: $bp-medium) {
      display: none;
    }

    &--2 {
      width: 25rem;
      margin-top: 2rem;

      @media only screen and (max-width: $bp-medium) {
        width: 13rem;
        margin-top: 1rem;
      }
    }
  }

  &__text {
    grid-row: 6/7;
    grid-column: 1/7;
    z-index: 100;
    padding: 2rem 6rem;
    display: grid;
    align-items: center;

    @media only screen and (max-width: $bp-medium) {
      padding: 1rem;
    }

    &--size {
      font-weight: 700;
      font-family: $font-display;
      line-height: 1.2;
      font-size: 4rem;

      @media only screen and (max-width: $bp-medium) {
        font-size: $font-12;
      }
    }
  }

  &__box {
    display: grid;
    align-items: center;
    justify-items: start;
    width: 40rem;

    @media only screen and (max-width: $bp-small) {
      width: 30rem !important;
    }
  }

  &__width {
    width: 40rem;

    @media only screen and (max-width: $bp-small) {
      width: 30rem !important;
    }
  }

  &__card {
    display: grid;
    justify-items: start;
    width: 40rem;

    @media only screen and (max-width: $bp-small) {
      width: 30rem !important;
    }
  }

  &__btn {
    width: 40rem;
    padding: 1.8rem 5rem !important;
    background-color: $c-btn-blue;
    box-shadow: 0px 0px 1px rgba(117, 131, 142, 0.04),
      0px 2px 4px rgba(52, 60, 68, 0.16);
    border-radius: 0.3rem !important;
    -webkit-border-radius: 0.3rem !important;
    -moz-border-radius: 0.3rem !important;
    -ms-border-radius: 0.3rem !important;
    -o-border-radius: 0.3rem !important;
    color: $c-white;
    font-weight: $weight-700;

    @media only screen and (max-width: $bp-large) {
      width: 100% !important;
    }
  }

  &__link {
    color: $c-white;
  }
}

.fin {
  &__links {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-column-gap: 3rem;
    padding: 3rem;
    border-bottom: 1px solid rgba($c-black, 0.1);
    background-color: $c-white;
    color: #000;
    position: relative;
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    -ms-border-radius: 0.3rem;
    -o-border-radius: 0.3rem;

    @media only screen and (max-width: $bp-medium) {
      width: 100% !important;
    }

    &:hover {
      cursor: pointer;

      Input,
      label {
        cursor: pointer;
      }
    }

    &--selected {
      border: 1px solid $c-btn-blue;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba($c-btn-blue, 0.13);
    }
  }

  &__svg {
    width: 4rem;
    left: 3rem;
  }

  &__tics {
    grid-column: 1/-1;
    display: grid;
    justify-items: end;
    z-index: 4;
    position: absolute;
    float: right;
    right: -2rem;
    top: 1rem;
  }

  &__tic {
    display: none;

    &--display {
      display: grid !important;
    }
  }
}

/* OTP */
.otp {
  &__story {
    height: 100vh;
    grid-column: full-start / col-end 4;

    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    background-color: $c-white;

    @media only screen and (max-width: $bp-medium) {
      height: 8rem;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      width: 100% !important;
      grid-row: 1/2;
      grid-column: 1/-1;
    }
  }

  &__content {
    height: 100vh;
    overflow-y: scroll;
    grid-column: col-start 5 / full-end;
    background-image: linear-gradient(147.98deg, $c-l-blue 0%, $c-d-blue 100%);
    padding: 2rem 0;
    display: grid;
    align-items: center;
    justify-items: center;
    color: $c-white;

    @media only screen and (max-width: $bp-medium) {
      grid-column: full-start/full-end;
      grid-row: 2/3;
      height: calc(100vh - 8rem);
    }
  }

  &__img {
    width: 100%;
    grid-row: 1/6;
    grid-column: 1/6;
    object-fit: cover;
    object-position: 100%;
    display: grid;
    align-self: center;
    align-content: center;
    z-index: 20;

    @media only screen and (max-width: $bp-medium) {
      display: none;
    }

    &--2 {
      width: 25rem;
      margin-top: 2rem;

      @media only screen and (max-width: $bp-medium) {
        width: 13rem;
        margin-top: 1rem;
      }
    }
  }

  &__text {
    grid-row: 6/7;
    grid-column: 1/7;
    z-index: 100;
    padding: 2rem 6rem;
    display: grid;
    align-items: center;

    @media only screen and (max-width: $bp-medium) {
      padding: 1rem;
    }

    &--size {
      font-weight: 700;
      font-family: $font-display;
      line-height: 1.2;
      font-size: 4rem;

      @media only screen and (max-width: $bp-medium) {
        font-size: $font-12;
      }
    }
  }

  &__box {
    display: grid;
    align-items: center;
    justify-items: start;
    width: 33rem !important;

    @media only screen and (max-width: $bp-small) {
      width: 30rem !important;
    }
  }

  &__btn {
    width: 32.5rem !important;
    padding: 1.8rem 5rem !important;
    background-color: $c-btn-blue;
    box-shadow: 0px 0px 1px rgba($c-btn-blue, 0.13),
      0px 2px 4px rgba($c-btn-blue, 0.13);
    border-radius: 0.3rem !important;
    -webkit-border-radius: 0.3rem !important;
    -moz-border-radius: 0.3rem !important;
    -ms-border-radius: 0.3rem !important;
    -o-border-radius: 0.3rem !important;
    color: $c-white;
    font-weight: $weight-700;

    @media only screen and (max-width: $bp-small) {
      width: 30rem !important;
    }
  }

  &__link {
    color: $c-white;
  }

  &__resends {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 32.5rem !important;

    @media only screen and (max-width: $bp-small) {
      width: 30rem !important;
    }
  }

  &__resend {
    display: grid;
    justify-content: end;
    align-items: center;
    font-size: $font-12;

    &:hover {
      cursor: pointer;
    }
  }
}

/* Forgot */
.enterEmail {
  &__story {
    height: 100vh;
    grid-column: full-start / col-end 4;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    background-color: $c-white;

    @media only screen and (max-width: $bp-medium) {
      height: 8rem;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      width: 100% !important;
      grid-row: 1/2;
      grid-column: 1/-1;
    }
  }

  &__content {
    height: 100vh;
    overflow-y: scroll;
    grid-column: col-start 5 / full-end;
    background-image: linear-gradient(147.98deg, $c-l-blue 0%, $c-d-blue 100%);
    padding: 2rem 0;
    display: grid;
    align-items: center;
    justify-items: center;
    color: $c-white;

    @media only screen and (max-width: $bp-medium) {
      grid-column: full-start/full-end;
      grid-row: 2/3;
      height: calc(100vh - 8rem);
    }
  }

  &__img {
    width: 100%;
    grid-row: 1/6;
    grid-column: 1/6;
    object-fit: cover;
    object-position: 100%;
    display: grid;
    align-self: center;
    align-content: center;
    z-index: 20;
    padding: 2rem 0 2rem 6rem;

    @media only screen and (max-width: $bp-medium) {
      display: none;
    }

    &--2 {
      width: 25rem;
      margin-top: 2rem;

      @media only screen and (max-width: $bp-medium) {
        width: 13rem;
        margin-top: 1rem;
      }
    }
  }

  &__text {
    grid-row: 6/7;
    grid-column: 1/7;
    z-index: 100;
    padding: 2rem 6rem;
    display: grid;
    align-items: center;

    @media only screen and (max-width: $bp-medium) {
      padding: 1rem;
    }

    &--size {
      font-weight: 700;
      font-family: $font-display;
      line-height: 1.2;
      font-size: 4rem;

      @media only screen and (max-width: $bp-medium) {
        font-size: $font-12;
      }
    }
  }

  &__box {
    display: grid;
    align-items: center;
    justify-items: start;
    width: 33rem !important;

    @media only screen and (max-width: $bp-small) {
      width: 30rem !important;
    }
  }

  &__w32 {
    width: 32.5rem !important;

    @media only screen and (max-width: $bp-small) {
      width: 30rem !important;
    }
  }

  &__btn {
    width: 32.5rem !important;
    padding: 1.8rem 5rem !important;
    background-color: $c-btn-blue;
    box-shadow: 0px 0px 1px rgba($c-btn-blue, 0.13),
      0px 2px 4px rgba($c-btn-blue, 0.13);
    border-radius: 0.3rem !important;
    -webkit-border-radius: 0.3rem !important;
    -moz-border-radius: 0.3rem !important;
    -ms-border-radius: 0.3rem !important;
    -o-border-radius: 0.3rem !important;
    color: $c-white;
    font-weight: $weight-700;

    @media only screen and (max-width: $bp-small) {
      width: 30rem !important;
    }
  }

  &__link {
    color: $c-white;
  }
}

/* Info */
.gaap {
  &__story {
    background-color: $c-f1;
    grid-column: full-start / col-end 4;
    padding: 2rem;
    height: calc(100vh - 6rem);
    display: grid;
    align-items: center;
    justify-items: center;

    @media only screen and (max-width: $bp-large) {
      grid-column: full-start/ full-end;
      height: 100%;
      padding: 1.5rem;
    }
  }

  &__img {
    width: 100%;
  }

  &__content {
    background-color: $c-f1;
    grid-column: col-start 5 / full-end;
    height: calc(100vh - 6rem);
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 0 5rem;

    @media only screen and (max-width: $bp-large) {
      grid-column: full-start/ full-end;
      height: 100%;
      padding: 1.5rem;
    }
  }

  &__box {
    background-color: $c-white;
    padding: 3rem;
    border: 1px solid #f3f3f3;
    box-sizing: border-box;
    box-shadow: 0px 2.4rem 3.4rem rgba($c-btn-blue, 0.13);
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    -ms-border-radius: 0.3rem;
    -o-border-radius: 0.3rem;

    @media only screen and (max-width: $bp-medium) {
      box-shadow: 0px 1rem 1rem rgba($c-btn-blue, 0.13);
      grid-column: full-start/ full-end;
      padding: 1rem;
    }
  }

  &__title {
    font-weight: $weight-500;
    color: $c-btn-blue;
    padding-bottom: 3rem;
    border-bottom: 1px solid $c-f3;
  }

  &__sub {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-gap: 2rem;
    justify-items: center;

    // @media only screen and (max-width: $bp-medium) {
    //   grid-gap: 2rem;
    // }

    &-center {
      align-items: center;
    }
  }

  &__phones {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: start;
    grid-gap: 2rem;
    padding: 2rem 0;
    border-bottom: 1px solid $c-f3;
    font-size: $font-24;

    @media only screen and (max-width: $bp-medium) {
      grid-template-columns: 1fr;
    }
  }

  // &__icons {
  //   margin-top: 1rem;
  // }
  &__btns {
    display: grid;
    justify-content: center;
    align-items: center;
  }

  &__btn {
    padding: 1.5rem 6rem !important;
    display: grid;
    grid-template-columns: max-content;
    // @media only screen and (max-width: $bp-medium) {
    //   padding: 1.5rem 3rem !important;
    // }
  }

  &__text {
    font-size: $font-18;
  }
}

/* SideNav */
.sidenavs .sidenav .sidenav__content .sidenav__archives {
  display: none;
  z-index: 500;
  @media only screen and (max-width: $bp-medium) {
    display: block;
  }
}

.sidenavs .sidenav .sidenav__content .sidenav__archives {
  z-index: 600;
  display: block;
  margin-left: 1rem;
}

.sidenavs .sidenav .sidenav__content {
  display: none;
  z-index: 500;
  @media only screen and (max-width: $bp-medium) {
    display: block;
    margin-left: 1rem;
  }
}

.sidenavs:hover .sidenav .sidenav__content {
  z-index: 600;
  display: block;
  margin-left: 1rem;
}

.sidenavs {
  grid-column: full-start / col-end 1;

  min-height: calc(100vh - 7rem);
  top: 7rem;
  position: fixed;
  // box-shadow: 0px 4px 24px rgba($c-btn-blue, 0.13);
  background-color: $c-white;
  text-transform: capitalize;
  padding-top: 4rem;
  width: min-content;

  &:hover {
    width: 15%;
    z-index: 800;

    @media only screen and (max-width: $bp-medium) {
      width: 100%;
    }
  }
  @media only screen and (max-width: $bp-medium) {
    grid-column: full-start / full-end;
    min-height: auto;
    display: grid;
    grid-auto-flow: column;
    position: relative;
    padding-top: 0;
    padding-left: 1rem;
    width: 100%;
    margin: 2rem 0;
    background-color: transparent;
    overflow-y: scroll;
    overflow-x: scroll;
    max-width: 100% !important;
    z-index: 10;
  }
}

.sidenav {
  // position: fixed;

  @media only screen and (max-width: $bp-medium) {
    height: auto;
    display: grid;
    grid-auto-flow: column;
  }

  &__archives {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    justify-items: start;
    // grid-gap: 1rem;
    padding: 1rem 0 1rem 0.8rem;
    color: $c-aaa;

    @media only screen and (max-width: $bp-medium) {
      padding: 0 2.4rem;
    }
  }
  &__archive {
    display: grid;
    align-items: center;
    justify-items: center;
    width: 4rem;
    height: 4rem;
    background-color: rgba($c-btn-blue, 0.2);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }
  &__li {
    display: grid;
    grid-template-columns: min-content 1fr;
    align-items: center;
    justify-items: start;
    // grid-gap: 1rem;
    padding: 1rem 2rem;

    &--2 {
      display: grid;
      grid-gap: 1rem;
      padding: 2rem;
      border-bottom: 1px solid $c-c9;
    }
    @media only screen and (max-width: $bp-medium) {
      grid-template-columns: min-content max-content;
      border-radius: 10rem;
      -webkit-border-radius: 10rem;
      -moz-border-radius: 10rem;
      -ms-border-radius: 10rem;
      -o-border-radius: 10rem;
      border: 1px solid $c-black;
      margin-right: 1rem;
      padding: 1.5rem 2.4rem;

      &:hover {
        border: 1px solid $c-btn-blue !important;
      }
    }
    &:hover {
      background-color: $c-btn-blue;
      cursor: pointer;
      color: $c-white;
    }
  }
  &__active {
    background-color: $c-btn-blue;
    border: 1px solid $c-btn-blue;
    color: $c-white;
  }
  &__active &__svg {
    fill: $c-white;
  }
  &__svg {
    fill: black;
    width: 1.6rem;
    height: 1.6rem;
  }
  &__li:hover &__svg {
    fill: $c-white;
    background-color: rgba($c-l-blue, 0.07) !important;
    color: $c-black;
  }
  //
  &__liBlue {
    display: grid;
    grid-template-columns: min-content 1fr;
    align-items: center;
    justify-items: start;
    padding: 1rem 2rem;

    &--2 {
      display: grid;
      grid-gap: 1rem;
      padding: 2rem;
      border-bottom: 1px solid $c-c9;
    }
    @media only screen and (max-width: $bp-medium) {
      grid-template-columns: min-content max-content;
      border-radius: 10rem;
      -webkit-border-radius: 10rem;
      -moz-border-radius: 10rem;
      -ms-border-radius: 10rem;
      -o-border-radius: 10rem;
      border: 1px solid $c-black;
      margin-right: 1rem;
      padding: 1.5rem 2.4rem;

      &:hover {
        border: 1px solid rgba($c-l-blue, 0.07);
      }
    }
    &:hover {
      background-color: rgba($c-l-blue, 0.07);
      cursor: pointer;
      color: $c-white;
    }
  }
  &__activeBlue {
    background-color: rgba($c-l-blue, 0.07);
    color: $c-black;
  }
  &__activeBlue &__svgBlue {
    fill: $c-btn-blue;
  }
  &__svgBlue {
    fill: $c-btn-blue;
    width: 1.6rem;
    height: 1.6rem;
  }
  &__liBlue:hover &__svgBlue {
    fill: $c-btn-blue;
  }
}

/* Top Nav */
.topnav {
  grid-column: full-start / full-end;
  display: grid;
  // grid-template-columns: repeat(3, 1fr);
  grid-template-columns: min-content max-content 1fr;
  grid-column-gap: 2rem;

  align-items: center;
  position: fixed;
  width: 100vw;
  height: 7rem;
  background-color: $c-white;
  box-shadow: 0px 4px 24px rgba($c-btn-blue, 0.13);
  padding: 0 3rem 0 2rem;
  z-index: 99;

  &__navAccesRight {
    grid-template-columns: min-content max-content 1fr;
    grid-column-gap: 2rem;
  }

  @media only screen and (max-width: $bp-medium) {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 1rem;
  }
  &__borderRight {
    border-right: #d9d9d9 solid 1px;
    height: 4rem !important;
    padding-right: 1rem !important;
  }
  &__borderLeft {
    border-left: #d9d9d9 solid 1px;
    height: 4rem !important;
    padding-left: 1rem !important;
  }
  &__title {
    display: grid;
    align-items: center;
    justify-items: center;
    @media only screen and (max-width: $bp-medium) {
      display: none;
    }
  }
  &__ul {
    display: grid;
    justify-items: end;
  }
  &__img {
    width: 100%;
    object-fit: contain;
    overflow: hidden;
  }
  &__logos {
    display: grid;
    grid-template-columns: repeat(2, min-content) 1fr;
    grid-column-gap: 1rem;
    align-items: center;
  }
  &__logoSvg {
    display: grid;
    grid-template-columns: min-content 1fr;
    align-items: center;
  }
  &__logo {
    &--svg {
      margin-right: 2rem;
      @media only screen and (max-width: $bp-medium) {
        margin-right: 0;
      }
    }
    &--name {
      color: $c-blue;
      font-size: $font-20;

      @media only screen and (max-width: $bp-medium) {
        display: none;
      }
    }
  }
  &__logoSvg {
    width: 12rem;
  }
  &__bgbox {
    display: grid;
    align-items: center;
    justify-items: center;
    // background-color: $c-f3;
    background-color: $c-btn-blue;
    color: $c-white;
    width: 4rem;
    height: 4rem;
    overflow: hidden;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    &:hover {
      cursor: pointer;
    }
  }
  &__dropdown {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-column-gap: 1rem;
    align-items: center;
    justify-items: end;
    &:hover {
      cursor: pointer;
    }
  }

  &__pd {
    padding: 2rem !important;
  }
}

.reports {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-column-gap: 2rem;
  align-items: center;
  justify-content: end;
  height: 100%;

  &__column-4 {
    display: grid;
    grid-template-columns: repeat(4, max-content);
    grid-column-gap: 2rem;
    align-items: center;
    justify-content: end;
    height: 100%;
  }
}

.report {
  &__name {
    display: grid;
    grid-template-columns: max-content;
    height: 100%;
  }
  &__ul {
    background-color: $c-white;
    width: max-content;
    top: 7rem !important;
    box-shadow: 0rem 3rem 1rem rgba($c-btn-blue, 0.13);
    &--2 {
      top: 6rem !important;
      position: absolute !important;
      right: 23.5rem !important;
    }
  }
  &__accessBtn {
    display: grid;
    grid-template-columns: min-content max-content;
    grid-column-gap: 1rem;
    align-items: center;
    color: $c-red;
  }
  &__dropdown {
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-column-gap: 1rem;
    align-items: center;
    justify-items: end;
    &:hover {
      cursor: pointer;
    }
  }

  &__visible__none {
    visibility: 0;
    opacity: 0;
  }
}

.mobiles__menubar {
  display: none;
}

@media only screen and (max-width: $bp-medium) {
  .mobiles__menubar {
    display: block;
  }
  .mobiles {
    &__menubar {
      display: grid;
      align-items: center;
      justify-items: end;
    }
    &__svg {
      padding: 2rem 1rem;
    }
    &__accordArr {
      padding: 2rem;
    }
    &__subAccordion {
      padding: 2rem !important;
    }
    &__links {
      display: grid;
      grid-template-columns: min-content 1fr;
      align-items: center;
      grid-gap: 1rem;
      padding: 0 2rem;
      border-top: 1px solid $c-c9;
      color: $c-black;
      text-decoration: none;
      font-size: $font-20;

      &--2 {
        grid-template-columns: min-content 1fr;
        // padding: 2rem;
        // padding: 2rem 0 2rem 4rem !important;
        display: grid;
        align-items: center;
        grid-gap: 1rem;
        border-top: 1px solid $c-c9;
        color: $c-black;
        text-decoration: none;
        font-size: $font-20;
      }

      &:hover {
        background-color: rgba($c-l-blue, 0.07);
      }
      &__sub {
        grid-template-columns: min-content 1fr min-content;
      }
    }
    &__links:last-child {
      border-bottom: 1px solid $c-c9;
    }
    &__premium {
      background-color: $c-yellow-primary;
      color: $c-white;
      &:hover {
        background-color: $c-yellow-primary;
      }
      &__logo {
        width: 2rem;
      }
    }
  }
}

.subnav {
  grid-column: full-start / full-end;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;

  height: 6rem;
  background-color: $c-white;
  box-shadow: 0px 0.4rem 2.4rem rgba($c-btn-blue, 0.13);
  padding: 0 2rem;

  @media only screen and (max-width: $bp-medium) {
    grid-template-columns: 1fr;
    padding: 0;
  }

  // &__grid__max {
  //   display: grid;
  //   grid-template-columns: ;
  // }
  &__logut {
    display: grid;
    justify-content: end;
  }

  &__logo-link {
    display: grid;
    align-items: center;
  }

  @media only screen and (max-width: $bp-medium) {
    img {
      width: 10rem;
    }
  }

  &__mobile {
    display: none;

    @media only screen and (max-width: $bp-medium) {
      display: grid;
    }
  }

  &__desktop {
    @media only screen and (max-width: $bp-medium) {
      display: none;
    }
  }
}

/* Setup */
.sSetup {
  grid-column: 1/-1;
  background-color: $c-f1;

  // &__pd2{
  //   padding: 2rem !important;
  // }
  &__container {
    display: grid;
    align-items: center;
    align-content: center;
    justify-items: center;
    padding: 0 12rem;
    padding: 5rem 2rem;
    min-height: calc(100vh - 11rem);
    max-height: 100%;

    @media only screen and (max-width: $bp-medium) {
      height: 100%;
      padding: 0 1.5rem;
    }
  }

  &__uploader {
    display: grid;
    justify-items: end;

    @media only screen and (max-width: $bp-medium) {
      justify-items: start;
    }
  }

  &__upload {
    position: relative;
  }

  &__crossImg {
    position: absolute;
    top: 0;
    right: 0;
    width: 2.5rem;
    padding: 0.5rem;
    z-index: 5;
    background-color: $c-white;
    box-shadow: 0 0 1rem rgba($c-btn-blue, 0.13);
    border-radius: 10rem;
    -webkit-border-radius: 10rem;
    -moz-border-radius: 10rem;
    -ms-border-radius: 10rem;
    -o-border-radius: 10rem;

    &:hover {
      cursor: pointer;
    }
  }

  &__file {
    z-index: 1;
    position: absolute;
    width: 12rem;
    height: 12rem;
    opacity: 0;
  }

  &__zindex {
    z-index: 3 !important;
  }

  &__previewImg {
    width: 12rem;
    max-height: 12rem;
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    -ms-border-radius: 0.3rem;
    -o-border-radius: 0.3rem;
  }

  &__displayNone {
    @media only screen and (max-width: $bp-medium) {
      display: none;
    }
  }

  &__grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    color: $c-btn-blue;

    @media only screen and (max-width: $bp-medium) {
      grid-template-columns: 1fr;
      grid-gap: 1rem;
    }
  }

  &__grid--logo {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-gap: 2rem;
    color: $c-btn-blue;

    @media only screen and (max-width: $bp-medium) {
      grid-template-columns: 1fr;
      grid-gap: 1rem;
    }
  }

  &__label {
    &-financialYear {
      color: transparent;
    }
  }

  &__btns {
    display: grid;
    grid-column: 1/-1;
    justify-items: center;
  }

  &__btn {
    width: 32.5rem !important;
    background-color: $c-btn-blue;
    box-shadow: 0px 0px 1px rgba($c-btn-blue, 0.13),
      0px 2px 4px rgba($c-btn-blue, 0.13);
    border-radius: 1.3rem;
    -webkit-border-radius: 1.3rem;
    -moz-border-radius: 1.3rem;
    -ms-border-radius: 1.3rem;
    -o-border-radius: 1.3rem;
    color: $c-white;
    font-weight: $weight-700;
    padding: 1.8rem 12rem !important;
    display: grid;
    grid-template-columns: max-content min-content;
    grid-column-gap: 0.8rem;
    align-items: center;

    @media only screen and (max-width: $bp-medium) {
      width: 100%;
    }
  }

  &__blue {
    color: $c-btn-blue;
  }

  &__sameAddressBox {
    display: grid;
    grid-template-columns: 2rem 1fr;
    align-items: center; // new add
  }

  &__sameAddr {
    width: 2rem;
    height: 2rem;

    &:hover {
      cursor: pointer;
    }
  }

  &__details {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: 1fr min-content 1fr;
    grid-gap: 2rem;
    width: 100%;

    @media only screen and (max-width: $bp-medium) {
      grid-template-columns: 1fr;
    }
  }

  &__physicals {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    width: 100%;

    @media only screen and (max-width: $bp-medium) {
      grid-template-columns: 1fr;
    }
  }

  &__postals {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
    width: 100%;

    @media only screen and (max-width: $bp-medium) {
      grid-template-columns: 1fr;
      margin-top: 3rem;
    }
  }

  &__fullWidth {
    grid-column: 1/-1;
  }

  &__vertical {
    width: 1px;
    background: $c-c4;
    height: 100%;
  }
}

/* Setup Depreciation */
.Depreciation {
  grid-column: 1/-1;
  background-color: $c-f1;

  &__container {
    padding: 4rem 2rem;
    display: grid;
    align-items: center;
    align-content: center;
    text-align: center;
    justify-items: center;
    padding: 0 15rem;
    min-height: calc(100vh - 6rem);
    max-height: 100%;

    @media only screen and (max-width: $bp-medium) {
      padding: 4rem 0.8rem;
    }
  }

  &__blue {
    color: $c-btn-blue;
  }

  &__black {
    color: $c-black;
  }

  &__svg {
    fill: $c-gray;
    height: 5rem;
    width: 5rem;
    margin-top: 5rem;
  }

  &__para {
    padding-bottom: 5em;
    margin-top: 2rem;
    text-transform: capitalize;
  }

  &__form {
    width: 100%;
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(38rem, 39rem));
    justify-content: center;
    align-content: center;
    grid-gap: 2rem;
    margin: 4rem 0;
    width: 100%;

    @media only screen and (max-width: $bp-medium) {
      grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
      margin: 3rem 0;
    }
  }

  &__card {
    display: grid;
    align-items: center;
    justify-items: center;
    text-align: center;
    background-color: $c-white;
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    -ms-border-radius: 0.3rem;
    -o-border-radius: 0.3rem;
    border: 1px solid $c-white;
    position: relative;
    height: 20rem;

    &:hover {
      color: $c-btn-blue;
      border: 2px solid $c-btn-blue;
      cursor: pointer;

      .depricaition__svg {
        fill: $c-btn-blue;
      }
    }
  }

  &__selected--card {
    color: $c-btn-blue;
    border: 2px solid $c-btn-blue;
  }

  &__selected--svg {
    fill: $c-btn-blue !important;
  }

  &__btns {
    grid-column: 1/-1;
    display: grid;
    justify-items: center;
    margin-top: 8rem;
  }

  &__btn {
    background-color: $c-btn-blue;
    box-shadow: 0px 0px 1px rgba($c-btn-blue, 0.13),
      0px 2px 4px rgba($c-btn-blue, 0.13);
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    -ms-border-radius: 0.3rem;
    -o-border-radius: 0.3rem;
    color: $c-white;
    font-weight: $weight-700;
    padding: 1.8rem 12rem !important;
    display: grid;
    grid-template-columns: max-content min-content;
    grid-column-gap: 0.8rem;
    align-items: center;
  }
}

.jobcreate {
  &__links {
    display: grid;
    grid-template-columns: 1fr;
    padding: 3rem;
    align-items: center;
    width: 38rem !important;
    border-bottom: 1px solid rgba($c-black, 0.1);
    height: auto;
    background-color: $c-white;
    color: #000;
    position: relative;
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    -ms-border-radius: 0.3rem;
    -o-border-radius: 0.3rem;

    @media only screen and (max-width: $bp-medium) {
      width: 100% !important;
    }

    &:hover {
      cursor: pointer;

      Input,
      label {
        cursor: pointer;
      }
    }

    &__selected {
      border: 2px solid $c-btn-blue;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba($c-btn-blue, 0.13);
    }

    &__alreadySelected {
      border: 2px solid $c-black;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba($c-btn-blue, 0.13);
    }
  }

  &__svg {
    width: 4rem;
    left: 3rem;
    position: absolute;
  }

  &__tic {
    display: none;

    &--display {
      display: block !important;
      top: 1rem;
      right: 1rem;
      position: absolute;
    }
  }
}

.jobcreate__checkbox {
  visibility: 0;
  opacity: 0.001;
}

/* Setup Classes */
.classes {
  grid-column: 1/-1;
  background-color: $c-f1;

  &__container {
    display: grid;
    align-items: center;
    align-content: center;
    text-align: center;
    justify-items: center;
    padding: 4rem 15rem;
    min-height: calc(100vh - 6rem);
    max-height: 100%;

    @media only screen and (max-width: $bp-medium) {
      // height: 100%;
      padding: 4rem 1rem;
    }
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    grid-gap: 2rem;
    margin: 2rem 0;
    width: 100%;

    @media only screen and (max-width: $bp-medium) {
      grid-template-columns: 1fr;
    }
  }

  &__card {
    display: grid;
    align-items: center;
    justify-items: center;
    text-align: center;
    background-color: $c-white;
    padding: 2rem;
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    -ms-border-radius: 0.3rem;
    -o-border-radius: 0.3rem;
    border: 1px solid $c-white;
    height: 20rem;

    &:hover {
      color: $c-btn-blue;
      border: 1px solid $c-btn-blue;
      cursor: pointer;

      .classes__svg {
        fill: $c-btn-blue;
      }
    }

    &__selected--card {
      color: $c-btn-blue;
      border: 1px solid $c-btn-blue;
    }

    &__selected--svg {
      fill: $c-btn-blue !important;
    }

    &__selected--svg--black {
      fill: red !important;
    }
  }

  &__btn {
    //width: 32.5rem !important;
    background-color: $c-btn-blue;
    box-shadow: 0px 0px 1px rgba($c-btn-blue, 0.13),
      0px 2px 4px rgba($c-btn-blue, 0.13);
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    -ms-border-radius: 0.3rem;
    -o-border-radius: 0.3rem;
    color: $c-white;
    font-weight: $weight-700;
    padding: 1.8rem 12rem !important;
    display: grid;
    grid-template-columns: max-content min-content;
    grid-column-gap: 0.8rem;
    align-items: center;

    @media only screen and (max-width: $bp-medium) {
      width: 100%;
      padding: 1.8rem 2rem !important;
    }
  }

  &__blue {
    color: $c-btn-blue;
  }

  &__svg {
    fill: $c-black;
    height: 3rem;
    width: 3rem;
    margin-bottom: 1rem;
  }
}

/* Records */
.record {
  grid-column: 1/-1;
  background-color: $c-f1;

  &__container {
    display: grid;
    align-items: center;
    align-content: center;
    text-align: center;
    justify-items: center;
    padding: 6rem 15rem;

    min-height: calc(100vh - 6rem);
    max-height: 100%;

    @media only screen and (max-width: $bp-medium) {
      padding: 4rem 1rem;
      margin-top: 5rem;
    }
  }

  &__back {
    height: 6rem;
  }

  &__records {
    display: grid;
    grid-template-columns: 30rem 1fr;
    grid-column-gap: 3rem;
    margin: 4rem 0;
    width: 100%;

    @media only screen and (max-width: $bp-medium) {
      grid-template-columns: 1fr;
      grid-column-gap: 0;
      grid-row-gap: 2rem;
    }
  }

  &__grid-2 {
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-gap: 1rem;
    padding: 1rem;
    text-align: left;
    font-size: $font-18;
    color: $c-btn-blue;
  }

  &__tab {
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-gap: 1rem;
    text-align: left;
    text-transform: capitalize;
    padding: 1rem 0;
    border-bottom: 1px solid $c-f3;

    @media only screen and (max-width: $bp-medium) {
      //grid-auto-flow: column;
      grid-template-columns: max-content 1fr;
      align-items: center;
      grid-column-gap: 6rem;
      margin-right: 2rem;
      background-color: $c-white;
      padding: 2rem;
      border-radius: 0.3rem;
      -webkit-border-radius: 0.3rem;
      -moz-border-radius: 0.3rem;
      -ms-border-radius: 0.3rem;
      -o-border-radius: 0.3rem;
    }
  }

  &__tabBtn {
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 0 2rem !important;
    height: 2.5rem;
    border-radius: 10rem !important;
    -webkit-border-radius: 10rem !important;
    -moz-border-radius: 10rem !important;
    -ms-border-radius: 10rem !important;
    -o-border-radius: 10rem !important;
    background-color: rgba($c-btn-blue, 0.3);
    color: $c-white;

    @media only screen and (max-width: $bp-medium) {
      background-color: $c-btn-blue;
    }

    &:hover {
      background-color: $c-btn-blue;
    }

    &-disable {
      padding: 0 2rem !important;
      background-color: #c4c4c4;
      height: 2.5rem;

      &:hover {
        background-color: #c4c4c4;
      }
    }
  }

  &__leftnav {
    padding: 1rem;
    background-color: $c-white;
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    -ms-border-radius: 0.3rem;
    -o-border-radius: 0.3rem;
    height: 42rem;
    overflow: hidden;
    overflow-y: scroll;

    @media only screen and (max-width: $bp-medium) {
      overflow-x: scroll;
      max-width: calc(100vw - 2rem);
      height: auto;

      display: grid;
      grid-auto-flow: column;
      align-items: center;
      background-color: transparent;
    }
  }

  &__searchDesktop {
    @media only screen and (max-width: $bp-medium) {
      display: none;
    }
  }

  &__searchMobile {
    display: none;

    @media only screen and (max-width: $bp-medium) {
      display: grid;
      margin: 2rem 0;
    }
  }

  &__rearrangeName {
    display: grid;
    justify-content: start;
    color: $c-btn-blue;
    margin: 1.1rem 0;
  }

  &__contents {
    padding: 3rem 2rem;
    background-color: $c-white;
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    -ms-border-radius: 0.3rem;
    -o-border-radius: 0.3rem;
    height: 42rem;
    overflow: hidden;
    overflow-y: scroll;

    @media only screen and (max-width: $bp-medium) {
      padding: 3rem 1rem;
      text-align: left;
    }
  }

  &__addRecord {
    width: 100%;
  }

  &__listname {
    display: grid;
    justify-self: start;
  }

  &__rearranges {
    display: grid;
    grid-template-columns: 1fr repeat(2, max-content);
    border-bottom: 1px solid $c-f3;
    padding: 1rem 0;
  }

  &__classBnts {
    background-color: rgba($c-btn-blue, 0.3);
    color: $c-btn-blue;
    padding: 0.6rem 1.2rem;
    border-radius: 0.3rem;
    margin-right: 2rem;
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    -ms-border-radius: 0.3rem;
    -o-border-radius: 0.3rem;
  }

  &__updowns {
    display: grid;
    grid-template-columns: repeat(3, min-content);
    justify-self: end;
    align-items: center;
  }

  &__arr {
    &-angle {
      &:hover {
        cursor: pointer;
      }
      &-0 {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
      }

      &-180 {
        &:hover {
          cursor: pointer;
        }
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
      }
    }
  }

  &__boxBtn {
    display: grid;
    justify-items: end;
    width: 100%;
  }

  &__plusBtn {
    padding: 0 2rem !important;
    border-radius: 10rem !important;
    -webkit-border-radius: 10rem !important;
    -moz-border-radius: 10rem !important;
    -ms-border-radius: 10rem !important;
    -o-border-radius: 10rem !important;

    background-color: $c-btn-blue;
    color: $c-white;

    &-red {
      background-color: $c-red;
    }

    &-grey {
      background-color: $c-gray;
    }

    &-blue {
      background-color: $c-white;
      color: $c-btn-blue;
      padding: 0 1rem 0 0 !important;
    }
  }

  &__btn {
    background-color: $c-btn-blue;
    box-shadow: 0px 0px 1px rgba($c-btn-blue, 0.13),
      0px 2px 4px rgba($c-btn-blue, 0.13);
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    -ms-border-radius: 0.3rem;
    -o-border-radius: 0.3rem;
    color: $c-white;
    font-weight: $weight-700;
    padding: 1.8rem 12rem !important;
    display: grid;
    grid-template-columns: max-content min-content;
    grid-column-gap: 0.8rem;
    align-items: center;
    justify-self: center;

    @media only screen and (max-width: $bp-medium) {
      padding: 1.8rem 2rem !important;
      width: 100% !important;
    }
  }

  &__blue {
    color: $c-btn-blue;
  }

  &__svg {
    fill: $c-black;
    height: 5rem;
    width: 5rem;
    margin-bottom: 1rem;
  }

  &__popupClasses {
    text-align: center;
    display: inline-block;
    padding: 0.6rem 1.2rem;
    margin: 0 1rem 1rem 0;
    border-radius: 10rem;
    -webkit-border-radius: 10rem;
    -moz-border-radius: 10rem;
    -ms-border-radius: 10rem;
    -o-border-radius: 10rem;

    &--selected {
      background-color: rgba($c-btn-blue, 0.3);
      color: $c-btn-blue;
      border: 1px solid rgba($c-btn-blue, 0.1);
    }

    &--unselected {
      background-color: $c-white;
      color: $c-gray;
      border: 1px solid $c-gray;
    }
  }

  &__arr-grey {
    fill: $c-gray;
  }

  &__arr-blue {
    fill: $c-btn-blue;
  }
}

/* Lists */
.listings {
  grid-column: col-start 1 / full-end;
  // grid-column: col-start 2 / full-end;

  background-color: $c-f3;
  margin: 7rem 2rem 2rem 2rem;

  @media only screen and (max-width: $bp-medium) {
    grid-column: full-start / full-end;
    margin: 7rem 1rem 2rem 1rem;
  }
}

.listing {
  box-shadow: 0px 4px 4px rgba($c-btn-blue, 0.13);
  padding: 2rem;
  background-color: $c-white;
  margin: 2rem 0;
  border-radius: 0.3rem;
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  -ms-border-radius: 0.3rem;
  -o-border-radius: 0.3rem;

  &__arvhiveHeader {
    margin-bottom: 3rem;
  }

  &__grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;

    @media only screen and (max-width: $bp-medium) {
      grid-template-columns: 1fr;
      grid-gap: 1rem;
    }
  }

  &__grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;

    @media only screen and (max-width: $bp-medium) {
      grid-template-columns: 1fr;
      grid-gap: 1rem;
    }
  }

  &__grid-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;

    @media only screen and (max-width: $bp-medium) {
      grid-template-columns: 1fr;
      grid-gap: 1rem;
    }
  }

  &__grid-5 {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 2rem;

    @media only screen and (max-width: $bp-medium) {
      grid-template-columns: 1fr;
      grid-gap: 1rem;
    }
  }

  &__grid-6 {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 2rem;

    @media only screen and (max-width: $bp-medium) {
      grid-template-columns: 1fr;
      grid-gap: 1rem;
    }
  }

  &__errorsms {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-column-gap: 1rem;
    align-items: center;
    padding: 0.7rem 1.2rem;
    margin-bottom: 2rem;
    color: $c-red;
    font-size: $font-15;
  }

  &__edit {
    padding: 0.8rem 2rem;
    border-radius: 10rem;
    -webkit-border-radius: 10rem;
    -moz-border-radius: 10rem;
    -ms-border-radius: 10rem;
    -o-border-radius: 10rem;
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 1rem;

    &--blue {
      background-color: $c-btn-blue;
      color: $c-white;
      border: 1px solid $c-btn-blue;
    }

    &--gray {
      border: 1px solid $c-gray;
      color: $c-gray;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__header {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 1rem;

    @media only screen and (max-width: $bp-medium) {
      display: none;
    }

    &--box {
      display: grid;
      grid-template-columns: 17rem repeat(2, max-content);
      justify-content: end;
      grid-gap: 1rem;

      @media only screen and (max-width: $bp-medium) {
        display: none;
      }
    }

    &__block {
      display: flex;
      align-items: center;
      margin: 0;
    }
  }

  &__header__mobile {
    display: none;
  }
  @media only screen and (max-width: $bp-medium) {
    &__header__mobile {
      display: grid;
      grid-template-columns: max-content 1fr;
      grid-gap: 1rem;
    }
  }

  &__tabs {
    margin-top: 2rem;
  }

  @media only screen and (max-width: $bp-medium) {
    &__tab {
      display: grid;
      grid-auto-flow: column;
      overflow-x: scroll;
    }
  }

  &__item {
    display: inline-block;
    padding: 1rem 2rem;
    margin-right: 1rem;
    margin-bottom: 2rem;
    color: $op-2;
    border-radius: 10rem;
    -webkit-border-radius: 10rem;
    -moz-border-radius: 10rem;
    -ms-border-radius: 10rem;
    -o-border-radius: 10rem;
    // border: 1px solid $c-f3;
    // box-shadow: 0 0 0.1rem rgba(117, 131, 142, 0.04), 0 0.2rem 0.4rem rgba(52, 60, 68, 0.16);

    @media only screen and (max-width: $bp-medium) {
      display: grid;
      grid-template-columns: max-content;
    }

    &--name {
      font-size: $font-15;
    }

    &:hover {
      background-color: $c-btn-blue;
      color: $c-white;
      cursor: pointer;
    }

    &__fairvalue {
      margin: 0;
      margin-left: 2rem;
      color: $c-black;
      border: 1px solid $c-btn-blue;
      box-shadow: 0 0 0.1rem rgba(117, 131, 142, 0.04),
        0 0.2rem 0.4rem rgba(52, 60, 68, 0.16);
    }

    &--alreadyfairvalue {
      background-color: $c-btn-blue;
      color: $c-white;
    }
  }

  &__pills {
    padding: 0.2rem 0.8rem;
    margin-left: 1rem;
    font-size: $font-15;
    border-radius: 10rem;
    -webkit-border-radius: 10rem;
    -moz-border-radius: 10rem;
    -ms-border-radius: 10rem;
    -o-border-radius: 10rem;

    &--white {
      background-color: white;
      color: $c-black;

      @media only screen and (max-width: $bp-medium) {
        width: max-content;
      }
    }

    &--gray {
      background-color: $op-2;
      color: $c-white;

      @media only screen and (max-width: $bp-medium) {
        width: max-content;
      }
    }
  }

  &__breadcrum-2 {
    margin: 2rem 0 0 0;
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;

    select {
      border: none;
      background: none;
      color: $c-btn-blue;
      border-bottom: 1px solid $c-btn-blue;
    }
  }

  &__breadcrum {
    margin: 2rem 0 0 0;
    display: grid;
    align-items: center;
    justify-items: end;
  }

  &__actions {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 0.2rem;
  }

  &__actions_archive {
    margin-right: 2rem;
  }

  &__workingStatus {
    width: 1.2rem;
  }

  &__bottom {
    position: relative;

    @media only screen and (max-width: $bp-medium) {
      position: static;
    }

    &__box {
      position: absolute;
      bottom: 2rem;
      font-size: $font-12;

      @media only screen and (max-width: $bp-medium) {
        position: static;
      }
    }
  }
}

/* Company Profile */
.companyProfiles {
  grid-column: full-start / full-end;
  background-color: $c-f3;
  margin-top: 8rem;
}

.companyClasses {
  grid-column: full-start / full-end;
  background-color: $c-f3;
}

.companyClasse {
  box-shadow: 0px 4px 4px rgba($c-btn-blue, 0.13);
  background-color: $c-white;
  border-radius: 7px;
  padding: 6rem;
  margin: 0 2rem 6rem 2rem;

  @media only screen and (max-width: $bp-small) {
    margin: 0 1rem 4rem 1rem;
    padding: 1rem;
  }
}

.pills {
  display: grid;
  grid-template-columns: max-content;
  justify-content: start;
  grid-gap: 1rem;
  margin-top: 2rem;
  overflow-x: scroll;
  grid-auto-flow: column;
  align-items: center;
  justify-items: center;

  @media only screen and (max-width: $bp-medium) {
    display: inline-block;
    grid-auto-flow: initial;
  }
}

.pill {
  display: grid;
  grid-template-columns: max-content;
  background-color: rgba($c-btn-blue, 0.2);
  color: $c-btn-blue;
  margin-bottom: 1rem;
  padding: 0.6rem 1.8rem;
  border-radius: 10rem;
  -webkit-border-radius: 10rem;
  -moz-border-radius: 10rem;
  -ms-border-radius: 10rem;
  -o-border-radius: 10rem;
}

.companyProfile {
  box-shadow: 0px 4px 4px rgba($c-btn-blue, 0.13);
  background-color: $c-white;
  border-radius: 0.3rem;
  padding: 2rem;
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  -ms-border-radius: 0.3rem;
  -o-border-radius: 0.3rem;

  @media only screen and (max-width: $bp-medium) {
    padding: 2rem;
    margin: 1rem;
    box-shadow: none;
    // background-color: transparent;
  }

  &__header {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;
    align-items: center;
    margin-bottom: 3rem;

    @media only screen and (max-width: $bp-medium) {
      grid-template-columns: 1fr;
      grid-row-gap: 2rem;
      grid-column-gap: 1rem;
    }

    &-end {
      justify-self: end;

      @media only screen and (max-width: $bp-medium) {
        justify-self: start;
      }
    }
  }

  &__breadcrum {
    margin: 2rem;
    display: grid;
    align-items: center;
    justify-items: end;

    @media only screen and (max-width: $bp-medium) {
      margin: 2rem;
    }
  }

  &__labels {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;

    @media only screen and (max-width: $bp-medium) {
      grid-template-columns: 1fr;
    }
  }

  &__label {
    &-name {
      margin-bottom: 2rem;
    }

    &-data {
      color: $c-gray;
    }

    &__icons {
      position: absolute;
      margin-left: -3rem;
      margin-top: 1.5rem;
    }
  }

  &__mtb {
    margin: 2rem 0;
  }

  &__mt {
    margin-top: 2rem;
  }

  &__links {
    display: grid;
    justify-items: end;
  }

  &__btn {
    width: 13rem;
    text-align: center;
  }
}

/* Premiums */
.defer {
  &__grid2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    @media only screen and (max-width: $bp-medium) {
      grid-template-columns: 1fr;
    }
  }
  &__mlss {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-gap: 1rem;
    align-items: center;
    margin-top: 0.8rem;
  }
  &__inputYear {
    display: grid;
    grid-column: 1/-1;
  }
  &__input {
    padding: 0.6rem 0.9rem !important;
    height: 3.8rem !important;
  }
  &__box {
    display: grid;
    grid-template-columns: 1fr min-content;
    align-items: center;
    // grid-gap: 1rem;
    justify-self: start;
  }
  &__years {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    grid-gap: 1rem;
    justify-items: start;
  }
  &__year {
    display: grid;
    align-items: center;
    justify-items: center;
  }
  &__width60 {
    width: 60%;
  }
}

.deferLists {
  grid-column: col-start 1 / full-end;
  background-color: $c-f3;
  margin: 7rem 2rem 2rem 2rem;

  @media only screen and (max-width: $bp-medium) {
    grid-column: full-start / full-end;
    margin: 7rem 1rem 2rem 1rem;
  }
}

.deferList {
  &__header {
    border-bottom: 1px solid $c-f1;
    padding: 1rem;
    font-size: $font-14;
    align-content: center;
    align-self: center;
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    -ms-border-radius: 0.3rem;
    -o-border-radius: 0.3rem;
    // display: grid;
    // grid-gap: 2rem;
    // grid-template-columns: 1.5fr repeat(7, 1fr);

    &:hover {
      background-color: $c-f3;
    }

    &--1 {
      display: grid;
      grid-gap: 2rem;
      grid-template-columns: 1.5fr repeat(1, 1fr);
    }

    &--2 {
      display: grid;
      grid-gap: 2rem;
      grid-template-columns: 1.5fr repeat(2, 1fr);
    }

    &--3 {
      display: grid;
      grid-gap: 2rem;
      grid-template-columns: 1.5fr repeat(3, 1fr);
    }

    &--4 {
      display: grid;
      grid-gap: 2rem;
      grid-template-columns: 1.5fr repeat(4, 1fr);
    }

    &--5 {
      display: grid;
      grid-gap: 2rem;
      grid-template-columns: 1.5fr repeat(5, 1fr);
    }

    &--6 {
      display: grid;
      grid-gap: 2rem;
      grid-template-columns: 1.5fr repeat(6, 1fr);
    }

    &--7 {
      display: grid;
      grid-gap: 2rem;
      grid-template-columns: 1.5fr repeat(7, 1fr);
    }
  }

  &__header:first-child {
    background-color: $c-f3;
  }
  &__TaxHeader {
    border-bottom: 1px solid $c-f1;
    padding: 1rem;
    font-size: $font-14;
    align-content: center;
    align-self: center;
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    -ms-border-radius: 0.3rem;
    -o-border-radius: 0.3rem;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 1.5fr repeat(3, 1fr);
  }
  &__right {
    display: grid;
    justify-self: end;
  }

  &__left {
    display: grid;
    justify-self: start;
  }
  &__bg {
    background-color: $c-f1;
  }
}

/* Dashboard */
.dashboard {
  &__cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    grid-gap: 1rem;
    margin-top: 2rem;
  }
  &__card {
    display: grid;
    grid-template-columns: min-content 1fr;
    background-color: $c-white;
    overflow: hidden;
    grid-column-gap: 1.5rem;
    font-size: $font-15;
    box-shadow: 0px 4px 4px rgba($c-btn-blue, 0.13);
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    -ms-border-radius: 0.3rem;
    -o-border-radius: 0.3rem;
  }
  &__organisation {
    border-bottom: 2px solid $c-btn-blue;
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
  &_graph {
    &--line {
      display: grid;
    }
  }
  &__story {
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 1.4rem;

    &--1 {
      background-image: $cricle-grad-blue;
    }
    &--2 {
      background-image: linear-gradient(
        135.85deg,
        #3b82f6 -1.52%,
        #5d5fef 100%
      );
    }
    &--3 {
      background-image: linear-gradient(
        135.85deg,
        $c-green-2 -1.52%,
        $c-green-2 100%
      );
    }
    &--4 {
      background-image: linear-gradient(135deg, #fb9200 0%, #ff1f00 100%);
    }
  }
  &__circle {
    display: grid;
    align-items: center;
    justify-items: center;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;

    &--1 {
      background-image: $cricle-grad-blue;
      background-image: linear-gradient(
        135.85deg,
        #3b82f6 -1.52%,
        #3b82f6 100%
      );
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    }
    &--2 {
      background-image: linear-gradient(
        135.85deg,
        #3b82f6 -1.52%,
        #5d5fef 100%
      );
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    }
    &--3 {
      background-image: $c-green-2;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    }
    &--4 {
      background-image: linear-gradient(135deg, #fb9200 0%, #ff1f00 100%);
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    }
  }
  &__content {
    display: grid;
    justify-items: start;
    align-content: center;
  }
  &__popupClasses {
    text-align: center;
    display: inline-block;
    padding: 0.6rem 1.2rem;
    margin: 0 1rem 1rem 0;
    border-radius: 10rem;
    -webkit-border-radius: 10rem;
    -moz-border-radius: 10rem;
    -ms-border-radius: 10rem;
    -o-border-radius: 10rem;

    &--selected {
      background-color: $c-btn-blue;
      color: $c-white;
      border: 1px solid rgba($c-btn-blue, 0.1);
    }
    &--unselected {
      color: $c-btn-blue;
      border: 1px solid $c-btn-blue;
    }
    &:hover {
      cursor: pointer;
      background-color: $c-btn-blue;
      color: $c-white;
      border: 1px solid rgba($c-btn-blue, 0.1);
    }
  }
  &__tabs {
    margin-top: 2rem;
  }
  &__tab {
    border-bottom: 2px solid rgba($c-c4, 0.5);
  }
  @media only screen and (max-width: $bp-medium) {
    &__tab {
      display: grid;
      grid-auto-flow: column;
      overflow-x: scroll;
    }
  }
  &__item {
    display: inline-block;
    padding: 1rem 2rem;
    margin-right: 1rem;
    margin-bottom: 2rem;
    color: $op-2;
    border-bottom: 2px solid transparent !important;

    // background: transparent !important;
    // color: $c-btn-blue !important;
    // border-bottom: 2px solid $c-btn-blue !important;
    // border: 1px solid $c-c4;
    // box-shadow: 0 0 0.1rem rgba(117, 131, 142, 0.04), 0 0.2rem 0.4rem rgba(52, 60, 68, 0.16);

    @media only screen and (max-width: $bp-medium) {
      display: grid;
      grid-template-columns: max-content;
    }
    &--name {
      font-size: $font-15;
    }
    &:hover {
      cursor: pointer;
      color: $c-btn-blue !important;
      border-bottom: 2px solid $c-btn-blue !important;
    }
  }
}

.dashboards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;

  @media only screen and (max-width: $bp-medium) {
    grid-template-columns: 1fr;
  }

  &__cards {
    box-shadow: 0px 0.4rem 2.4rem rgba($c-btn-blue, 0.13);
    padding: 2rem;
    background-color: $c-white;
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    -ms-border-radius: 0.3rem;
    -o-border-radius: 0.3rem;
  }
  &__card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-content: start;
    grid-gap: 1rem;
    height: calc(100vh - 18rem);
    overflow: scroll;
  }
  &__end {
    display: grid;
    justify-self: end;
    align-self: center;
  }
  &__underline {
    display: grid;
    grid-column: 1/-1;
    border-bottom: 1px solid rgba($c-black, 0.1);
  }
  &__dots {
    display: grid;
    align-items: center;
    grid-template-columns: min-content 1fr;
    grid-column-gap: 1rem;
  }
  &__dot {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;

    &--blue {
      background-color: $c-btn-blue;
    }
    &--gray {
      background-color: $c-aaa;
    }
    &--black {
      background-color: $c-black;
    }
  }
  &__blueBg {
    color: $c-btn-blue;
  }
  &__subFull {
    display: grid;
    grid-column: 1/-1;
    font-size: $font-12;
  }
  &__gray {
    color: $c-aaa;
    font-size: $font-12;
  }
  &__shortings {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: 1rem;
    align-items: center;
    margin-bottom: 2rem;
  }
  &__shorting {
    display: inline-block;
    text-align: center;
    padding: 0.6rem 1.2rem;

    &__select {
      border: 1px solid $c-black;
      padding: 0.4rem 1rem;
      margin-right: 0.6rem;
      font-size: 1.3rem;
      font-weight: $weight-400;
      border-radius: 10rem;
      -webkit-border-radius: 10rem;
      -moz-border-radius: 10rem;
      -ms-border-radius: 10rem;
      -o-border-radius: 10rem;

      &__selected,
      &:hover {
        cursor: pointer;
        border: 1px solid $c-btn-blue;
        color: $c-btn-blue;
      }
    }
  }
}

/* Add Asset */
.addItems {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  grid-gap: 2rem;

  &__grid--2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;

    @media only screen and (max-width: $bp-medium) {
      grid-template-columns: 1fr;
    }
  }
}

/* Asset Info */
.assetinfo {
  &__cross {
    display: grid;
    grid-template-columns: 1fr min-content;
    align-items: center;
    margin-bottom: 2rem;
  }
  &__gridHistory {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-column-gap: 2rem;
    // color:#006AB0;
    align-items: center;
    @media only screen and (max-width: $bp-medium) {
      grid-template-columns: 1fr;
    }
  }
  &__crossIimg {
    width: 1.5rem;
    height: 1.5rem;
  }
  &__history {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;

    @media only screen and (max-width: $bp-medium) {
      grid-template-columns: max-content;
      grid-gap: 1rem;
      justify-items: start;
    }
  }
  &__empty {
    position: absolute;
    // bottom: 35%;
    // right: 28%;
    width: calc(100% - 4rem);
    height: calc(100% - 18rem);
    display: grid;
    align-items: center;
    align-content: center;
    justify-items: center;
  }
  &__EmptySvg {
    width: 20rem;
    height: 20rem;
  }
  &__title,
  &__data {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1rem;
    margin-bottom: 1rem;

    @media only screen and (max-width: $bp-small) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &__para {
    color: #9b9b9b;
    margin-top: 1rem;
  }
  &__edit {
    &:hover {
      // opacity: .8;
      color: $c-black !important;
    }
  }
  &__errorsms {
    display: grid;
    grid-template-columns: min-content 1fr auto;
    grid-column-gap: 1rem;
    align-items: center;
    border-radius: 7px;
    padding: 0.7rem 1.2rem;
    margin: 2rem 0;
    border: 1px solid $c-red;
    background-color: $c-f8;
    color: $c-red;
  }
  &__btns {
    display: grid;
    justify-items: end;
  }
  &__btn {
    background-color: $c-red;
    color: $c-white;
  }
}

/* Edit Asset */
.editItem {
  &__header {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 1rem;
    margin-bottom: 3rem;

    &--box {
      display: grid;
      grid-template-columns: repeat(3, max-content);
      justify-content: end;
      align-items: center;
      grid-column-gap: 1rem;
    }
  }
  &__btn--1 {
    color: #79c14c;
    background-color: rgba(#79c14c, 0.2);
    border-radius: 0.7rem;
    -webkit-border-radius: 0.7rem;
    -moz-border-radius: 0.7rem;
    -ms-border-radius: 0.7rem;
    -o-border-radius: 0.7rem;
  }
  &__btn--2 {
    color: $c-btn-blue;
    background-color: rgba($c-btn-blue, 0.2);
    border-radius: 0.7rem;
    -webkit-border-radius: 0.7rem;
    -moz-border-radius: 0.7rem;
    -ms-border-radius: 0.7rem;
    -o-border-radius: 0.7rem;
  }
  &__cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    grid-gap: 2rem;
  }
  &__card {
    display: grid;
    align-items: center;
    justify-items: center;
    // background: #c9e2ff;
    box-shadow: 0px 1.4rem 2.4rem rgba($c-black, 0.05);
    border-radius: 1rem;
    padding: 4rem 2rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
    text-transform: capitalize;
    border: 2px solid transparent;

    &__selected {
      border: 2px solid $c-btn-blue;
    }
    &:hover {
      cursor: pointer;
    }
  }
}

.accMgmt {
  &__grid--2 {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 2rem;
    align-items: center;
    justify-content: start;
  }
  &__edit {
    padding: 1.7rem 3.4rem;
    border-radius: 10rem;
    -webkit-border-radius: 10rem;
    -moz-border-radius: 10rem;
    -ms-border-radius: 10rem;
    -o-border-radius: 10rem;
    text-align: center;
    text-transform: capitalize;

    &--blue {
      background-color: $c-btn-blue;
      color: $c-white;
      border: 1px solid $c-btn-blue;
    }
    &--gray {
      border: 1px solid $c-gray;
      color: $c-gray;
    }
    &:hover {
      cursor: pointer;
    }
  }

  &__access {
    &--readOnly {
      background-color: rgba($c-green, 0.2);
      color: $c-green;
      padding: 0.4rem 1rem;
      border-radius: 10rem;
      -webkit-border-radius: 10rem;
      -moz-border-radius: 10rem;
      -ms-border-radius: 10rem;
      -o-border-radius: 10rem;
    }
    &--fullAccess {
      background-color: rgba($c-btn-blue, 0.2);
      color: $c-btn-blue;
      padding: 0.4rem 1rem;
      border-radius: 10rem;
      -webkit-border-radius: 10rem;
      -moz-border-radius: 10rem;
      -ms-border-radius: 10rem;
      -o-border-radius: 10rem;
    }
  }
  &__link {
    margin-right: 1rem;
  }
}

/* Investment Model Box */
.messages {
  grid-column: col-start 4 / col-end 5;
  background-color: #f3f3f3;
  margin: 0rem 2rem 2rem 2rem;
  @media only screen and (max-width: $bp-medium) {
    grid-column: full-start / full-end;
    margin: 6rem 1rem 1rem 1rem;
  }
}

.message {
  &__box {
    box-shadow: 0px 4px 4px rgba($c-btn-blue, 0.13);
    padding: 2rem;
    background-color: #fff;
    margin: 0;
    display: grid;
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    -ms-border-radius: 0.3rem;
    -o-border-radius: 0.3rem;
  }
  &__img {
    display: grid;
    justify-items: center;
    padding: 2rem 0;
  }
  &__svg {
    width: 30rem;
    @media only screen and (max-width: $bp-medium) {
      width: 25rem;
    }
  }
  &__link {
    display: grid;
    align-items: center;
    text-align: center;
    padding: 1.6rem;
    margin-top: 2rem;
    background: $c-white;
    border: 1px solid #c4c4c4;
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    -ms-border-radius: 0.3rem;
    -o-border-radius: 0.3rem;
    &:hover {
      color: $c-white;
      background-color: $c-btn-blue;
      border: 1px solid $c-btn-blue;
    }
  }
}

/* Contact */
.contactUs {
  &__story {
    background-color: $c-f1;
    grid-column: full-start / col-end 4;
    padding: 10rem;
    // height: calc(100vh - 7rem);
    height: 80vh;
    display: grid;
    align-items: center;
    justify-items: center;

    @media only screen and (max-width: $bp-large) {
      grid-column: full-start/ full-end;
      height: 100%;
      padding: 1rem;
      margin-top: 6rem;
    }
  }
  &__img {
    width: 100%;
  }
  &__content {
    background-color: $c-f1;
    grid-column: col-start 5 / full-end;
    // height: calc(100vh - 7rem);
    height: 80vh;
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 0 5rem;

    @media only screen and (max-width: $bp-large) {
      grid-column: full-start/ full-end;
      height: 100%;
      padding: 1rem;
    }
  }
  &__box {
    background-color: $c-white;
    padding: 3rem;
    border: 1px solid #f3f3f3;
    box-sizing: border-box;
    box-shadow: 0px 2.4rem 3.4rem rgba($c-btn-blue, 0.13);
    border-radius: 0.3rem;

    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    -ms-border-radius: 0.3rem;
    -o-border-radius: 0.3rem;
    @media only screen and (max-width: $bp-medium) {
      box-shadow: 0px 1rem 1rem rgba($c-btn-blue, 0.13);
      grid-column: full-start/ full-end;
      padding: 1rem;
    }
  }
  &__title {
    font-weight: $weight-500;
    color: $c-btn-blue;
    padding-bottom: 3rem;
    border-bottom: 1px solid $c-f3;
  }
  &__sub {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-gap: 2rem;
    justify-items: center;

    &-center {
      align-items: center;
    }
  }
  &__phones {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: start;
    grid-gap: 2rem;
    padding: 2rem 0;
    border-bottom: 1px solid $c-f3;
    font-size: $font-24;
    @media only screen and (max-width: $bp-medium) {
      grid-template-columns: 1fr;
    }
  }
  &__btns {
    display: grid;
    justify-content: center;
    align-items: center;
  }
  &__btn {
    padding: 1.5rem 6rem !important;
    display: grid;
    grid-template-columns: max-content;
  }
  &__text {
    font-size: $font-18;
  }
}

/*------- Developer Class -----------*/
.listings .classes__container {
  padding: 4rem;
}

.qr-box {
  display: flex;
  justify-content: center;
}

.dot-box {
  display: none;
  width: 0;
  height: 0;
}

.cps {
  text-transform: capitalize;
}

.ps-1 {
  padding-left: 1rem;
}

.d-none {
  display: none !important;
}

.d-hidden {
  visibility: hidden !important;
}

.cursor-pointer {
  cursor: pointer;
}

/* Asset Table */
.asset-listing .hbotQl {
  overflow: unset !important;
  white-space: unset !important;
  text-overflow: unset !important;
  text-align: right;
  min-width: 100px;
}

.rdt_TableRow:hover {
  background-color: $c-btn-blue !important;
  color: $c-white !important;
}

.rdt_TableRow:hover img {
  filter: grayscale(1) brightness(255) !important;
}

.scroll-content {
  max-height: 70vh;
  overflow-y: scroll;
}

.scroll-content-50-min-height {
  min-height: 50vh;
}
