%heading {
  font-weight: 700;
  font-family: $font-display;
  line-height: 1.2;
}
%heading-600 {
  font-weight: 600;
  font-family: $font-display;
}
%heading-500 {
  font-weight: 500;
  font-family: $font-display;
}

.heading-1 {
  @extend %heading;
  font-size: 4rem;

  @media only screen and (max-width: $bp-medium){
    font-size: 3.5rem;
  }
}

.heading-2 {
  @extend %heading;
  font-size: 3rem;

  @media only screen and (max-width: $bp-medium){
    font-size: 3rem;
  }
}

.heading-3{
  @extend %heading;
  font-size: $font-24;

  @media only screen and (max-width: $bp-medium){
    font-size: 2rem;
  }
}
.heading-4{

  @extend %heading-600;
  font-size: $font-18;

  @media only screen and (max-width: $bp-medium){
    font-size: 1.6rem;
  }
}

.heading-5{
  @extend %heading-600;
  font-size: $font-15;

  @media only screen and (max-width: $bp-medium){
    font-size: 1.4rem;
  }
}
.heading-6{
  @extend %heading-600;
  font-size: $font-12;
}
