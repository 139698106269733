.m-0 {
  margin: 0 !important;
}

.u-center-text {
  text-align: center !important;
}
// margin bottom
.mb-s-sm {
  margin-bottom: 1rem !important;
}

.mb-sm {
  margin-bottom: 2rem !important;
}

.mb-md {
  margin-bottom: 3rem !important;
}
.mb-md-4 {
  margin-bottom: 4rem !important;
}

.mb-bg {
  margin-bottom: 6rem !important;
}
.mb-hub {
  margin-bottom: 10rem !important;
}
// margin right
.mr-ss-sm {
  margin-right: 0.5rem !important;
}
.mr-s-sm {
  margin-right: 1rem !important;
}
.mr-sm {
  margin-right: 2rem !important;
}
// margin left
.ml-s-sm {
  margin-left: 1rem !important;
}
.ml-sm {
  margin-left: 2rem !important;
}
.ml-sm {
  margin-left: 2rem !important;
}
// margin to
.mt-03-sm {
  margin-top: 0.3rem !important;
}
.mt-s-sm {
  margin-top: 1rem !important;
}
.mt-sm {
  margin-top: 2rem !important;
}
.mt-md {
  margin-top: 3rem !important;
}
.mt-md-4 {
  margin-top: 4rem !important;
}

// padding
.pt-sm {
  padding-top: 2rem !important;
}
.pb0 {
  padding-bottom: 0 !important;
}
.pt0 {
  padding-top: 0 !important;
}

// text casess
.upper {
  text-transform: uppercase !important;
}
.cap {
  text-transform: capitalize !important;
}
.lower {
  text-transform: lowercase !important;
}

// Line-height
.line_height-15 {
  line-height: 1.5;
}

// Font Size

.font-10 {
  font-size: $font-10 !important;
}
.font-12 {
  font-size: $font-12 !important;
}
.font-14 {
  font-size: $font-14 !important;
}
.font-18 {
  font-size: $font-18 !important;
}
.font-20 {
  font-size: $font-20 !important;
}
.font-24 {
  font-size: $font-24 !important;
}

// width
.w-100 {
  width: 100% !important;
}

// Weight
.weight-400 {
  font-weight: $weight-400;
}
.weight-600 {
  font-weight: $weight-600;
}
.weight-700 {
  font-weight: $weight-700;
}

// border bottom

.border__bottom {
  border-bottom: 1px solid $c-white;

  &--gray {
    color: rgba($c-btn-blue, 0.5) !important;
    border-bottom: 1px solid rgba($c-btn-blue, 0.5) !important;
  }
  &--blue {
    color: rgba($c-d-blue, 0.9) !important;
    border-bottom: 1px solid rgba($c-d-blue, 0.9) !important;
  }
  &--danger {
    color: rgba($c-red, 0.8) !important;
    border-bottom: 1px solid rgba($c-red, 0.5) !important;
  }
}

//
.cursor {
  &:hover {
    cursor: pointer;
  }
}
a {
  color: $c-black;
  text-decoration: none;
  text-decoration: none;
}
.c-orange {
  color: $c-orange !important;
}
// Background
.bg__red {
  background-color: $c-red !important;
}
// color
.c__black {
  color: #000 !important;
}
.c__aa {
  color: #aaa !important;
}
.c__9b {
  color: $c-gray !important;
}
.c__white {
  color: #fff !important;
}
.c__blue {
  color: $c-btn-blue !important;
}
.c__yellow {
  color: $c-yellow !important;
}
.c__red {
  color: $c-red !important;
}

// opacity color
.op-2 {
  color: $op-2 !important;
}

.display__none {
  display: none;
}
.bp__largest__none {
  @media only screen and (max-width: $bp-largest) {
    display: none;
  }
}
.bp__large__none {
  @media only screen and (max-width: $bp-large) {
    display: none;
  }
}
.bp__medium__none {
  @media only screen and (max-width: $bp-medium) {
    display: none;
  }
}
.bp__min__medium__none {
  @media only screen and (min-width: $bp-medium) {
    display: none;
  }
}
.bp__smallLarge__none {
  @media only screen and (max-width: $bp-smallLarge) {
    display: none;
  }
}
.bp__min__smallLarge__none {
  @media only screen and (min-width: $bp-smallLarge) {
    display: none;
  }
}
.bp__small__none {
  @media only screen and (max-width: $bp-small) {
    display: none;
  }
}

.inputs {
  &__icons {
    position: relative;
  }
  &__svg {
    position: absolute;
    top: 35%;
    right: 2rem;
    @media only screen and (max-width: $bp-small) {
      top: 30%;
    }
  }
}

.not-allowed {
  &:hover {
    cursor: not-allowed !important;
  }
}

.justify__itmes__end {
  justify-items: end;
}
