.popup-content {
  margin: auto;
  background: #fff;
  width: 40%;
  padding: 5px;
  border: 1px solid #d7d7d7;
  border-radius: 0.3rem;
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  -ms-border-radius: 0.3rem;
  -o-border-radius: 0.3rem;

  @media only screen and (max-width: $bp-medium) {
    width: 100%;
  }

  &__width--80-content {
    width: 86%;

    @media only screen and (max-width: $bp-medium) {
      width: 100%;
    }
  }

  &__width--60-content {
    width: 60%;

    @media only screen and (max-width: $bp-medium) {
      width: 100%;
    }
  }
}

.popup {
  &__modal {
    padding: 2rem;
    // padding: 4rem 5.5rem;
    height: 100%;

    @media only screen and (max-width: $bp-medium) {
      padding: 3rem 1rem;
    }
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-column-gap: 1rem;
    margin-bottom: 4rem;
  }

  &__close {
    background-color: transparent;
    outline: none;
    border: none;

    &:hover {
      cursor: pointer;
    }
  }

  &__btn {
    padding: 1.2rem 4rem !important;
  }

  &__btns {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-column-gap: 1rem;
    align-items: center;
    justify-content: end;
  }

  &__btnsCenter {
    display: grid;
    grid-template-columns: repeat(2, min-content);
    grid-column-gap: 1rem;
    align-items: center;
    justify-content: center;
  }
}

// Hitory Popup Right
.history {
  &__popup {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 80;
  }

  &__content {
    margin-top: 6rem;
    padding: 1rem 0;
  }

  &__box {
    position: relative;
    width: 40%;
    height: 100vh;
    background: #fff;
    padding: 2rem;
    overflow: scroll;
    float: right;

    @media only screen and (max-width: $bp-medium) {
      width: 100%;
      padding: 1rem;
    }
  }
}

.close-icon {
  cursor: pointer;
  position: fixed;
  right: 40%;
  top: 0;
  background: transparent;
  width: 100%;
  height: 100vh;

  @media only screen and (max-width: $bp-medium) {
    display: none;
  }
}

// Hitory Popup Right
.menubars {
  &__popup {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 7000;
  }

  &__content {
    padding: 3rem 2rem;
  }

  &__box {
    position: relative;
    width: 40%;
    height: 100vh;
    background: #fff;
    padding: 2rem;
    overflow: scroll;
    float: right;

    @media only screen and (max-width: $bp-medium) {
      width: 100%;
      padding: 0;
    }
  }
}
