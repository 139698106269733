.container {
  display: -ms-grid;
  display: grid;
  grid-template-rows: min-content;
  grid-template-columns:
    [full-start] minmax(6rem, 1fr)
    [center-start] repeat(8, [col-start] minmax(min-content, 30rem) [col-end])
    [center-end] minmax(6rem, 1fr)
    [full-end];
  -ms-grid-template-columns: [full-start] minmax(6rem, 1fr) [center-start]
    repeat(8, [col-start] minmax(min-content, 30rem) [col-end]) [center-end]
    minmax(6rem, 1fr) [full-end];
}

.small_font {
  font-size: 1.2rem;
}
