// COLOR VARIABLES
$c-white: #fff;
$c-bg: #e5e5e5;
$c-e9: #e9e9e9;
$c-aaa: #aaa;
$c-f1: #f1f4f7;
$c-f3: #f3f3f3;
$c-black: #000;
$c-red: #ff5630;
$c-f8: #fff8f8; // alert danger background
$c-orange: #ff6b00;
$c-green: #36b37e;
$c-green-2: #79c14c;
$c-ef: #e3fcef; // alert success background
$c-yellow: #ffab00;
$c-yellow-primary: #fec006;
$c-e6: #fffae6; // alert success background
$c-c9: #c9e2ff; // border bottom line of drop-downs
$c-blue: #195bbb;
$c-blue-dard: #0067d5;
$c-l-blue: #289ce1;
$c-d-blue: #006ab0;
$c-btn-blue: #3b82f6;

$c-c4: #c4c4c4;
$c-gray: #9b9b9b;

// dashboard
$cricle-grad-blue: linear-gradient(#3b82f6 0%, #3b82f6 100%);

// opacity
$op-2: rgba($c-black, 0.2);

// FONT VARIABLES
$font-display: "Lato", sans-serif; // globle font

// Font Sizes
$font-1: 0.1rem;
$font-2: 0.2rem;
$font-3: 0.3rem;
$font-10: 1rem;
$font-11: 1.1rem;
$font-12: 1.2rem;
$font-13: 1.3rem;
$font-14: 1.4rem;
$font-15: 1.5rem;
$font-16: 1.6rem;
$font-17: 1.7rem;
$font-18: 1.8rem;
$font-19: 1.9rem;
$font-20: 2rem;
$font-24: 2.4rem;

$font-34: 3.4rem;
$font-38: 3.8rem;

// Font Weight
$weight-900: 900;
$weight-800: 800;
$weight-700: 700;
$weight-600: 600;
$weight-500: 500;
$weight-400: 400;
$weight-300: 300;
$weight-200: 200;

// RESPONSIVE BREACK POINTS
$bp-largest: 75em; // 1200px
$bp-large: 62.5em; // 1000px
$bp-medium: 50em; // 800px
$bp-smallLarge: 37.5em; // 600px
$bp-small: 30em; // 500px
