.subnav {
  grid-column: full-start / full-end;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;

  height: 6rem;
  background-color: $c-white;
  box-shadow: 0px 0.4rem 2.4rem rgba($c-btn-blue, 0.13);
  padding: 0 2rem;

  @media only screen and (max-width: $bp-medium) {
    grid-template-columns: 1fr;
    padding: 0;
  }
  // &__grid__max {
  //   display: grid;
  //   grid-template-columns: ;
  // }
  &__logut {
    display: grid;
    justify-content: end;
  }
  &__logo-link {
    display: grid;
    align-items: center;
  }
  @media only screen and (max-width: $bp-medium) {
    img {
      width: 10rem;
    }
  }

  &__mobile {
    display: none;
    @media only screen and (max-width: $bp-medium) {
      display: grid;
    }
  }
  &__desktop {
    @media only screen and (max-width: $bp-medium) {
      display: none;
    }
  }
}
