.alert-danger {
  color: $c-red;
  background-color: $c-f8;
}

.alert-success {
  color: $c-green;
  background-color: $c-ef;
}

.alert-warning {
  color: $c-yellow;
  background-color: $c-e6;
}

.alert-info {
  color: $c-yellow;
  background-color: $c-e6;
}

.notification-error,
.notification-success,
.notification-warning,
.notification-info {
  background-color: $c-white;
  opacity: 1;
  box-shadow: 0 0 1rem rgba($c-btn-blue, 0.13);
}

.notification-error .message,
.notification-error .title,
.notification-error::before {
  color: $c-red;
}

.notification-success .message,
.notification-success .title,
.notification-success::before {
  color: $c-green;
}

.notification-info .message,
.notification-info .title,
.notification-info::before {
  color: $c-btn-blue;
}

.notification-warning .message,
.notification-warning .title,
.notification-warning::before {
  color: $c-yellow;
}