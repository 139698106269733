.react-tabs__tab-list {
  border-bottom: 1px solid transparent !important;
  padding: 1rem !important;
}
.react-tabs__tab--selected {
  background: $c-btn-blue !important;
  color: $c-white !important;
  border-radius: 10rem !important;
  -webkit-border-radius: 10rem !important;
  -moz-border-radius: 10rem !important;
  -ms-border-radius: 10rem !important;
  -o-border-radius: 10rem !important;
  &:hover {
    color: $c-white !important;
  }
}
