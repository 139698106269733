// .react-date-picker {
//   border: 1px solid yellow !important;
//   color: green;
// }
.react-date-picker__wrapper {
  border: 1px solid transparent !important;
}

 .react-date-picker__calendar--open{
  z-index: 8888 !important;
}

.date__input {
  width: 100%;
  height: 5.4rem;
  padding: 0 2rem;
  box-sizing: border-box;
  -webkit-appearance: none;
  font-family: inherit;
  font-weight: 400;
  font-size: 1.6rem;
  border: 1px solid #c4c4c4;
  border-radius: 0.2rem;
  transition: all 0.3s;
  background-color: #fff;
  border-radius: 0.3rem;
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  -ms-border-radius: 0.3rem;
  -o-border-radius: 0.3rem;
}


.react-date-picker__inputGroup__input:invalid{
  background-color: white !important;
}
.react-date-picker__inputGroup__input {
  outline: none;
}